.container {
  position: relative;
  width: 100%;
}

.ad_header {
  width: 100%;
  height: 1.333333rem;
  position: relative;
  overflow: hidden;
}