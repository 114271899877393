.shake {
  -webkit-animation: shake 0.3s ease-out;
  animation: shake 0.3s ease-out;
}

@-webkit-keyframes shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%, 50% {
    -webkit-transform: translate3d(-0.2rem, 0, 0);
    transform: translate3d(-0.2rem, 0, 0);
  }

  30%, 70% {
    -webkit-transform: translate3d(0.2rem, 0, 0);
    transform: translate3d(0.2rem, 0, 0);
  }
}

@keyframes shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%, 50% {
    -webkit-transform: translate3d(-0.2rem, 0, 0);
    transform: translate3d(-0.2rem, 0, 0);
  }

  30%, 70% {
    -webkit-transform: translate3d(0.2rem, 0, 0);
    transform: translate3d(0.2rem, 0, 0);
  }
}