.tab {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0.533333rem auto 0;
  border-bottom: 0.026667rem solid #e7e7e7;
  color: #666666;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  font-size: 0.426667rem;
}

.tab li {
  height: 1.066667rem;
  line-height: 1.066667rem;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
}

.tab li.active_tab {
  font-weight: bold;
  color: #000;
}

.tab .active_tab span {
  color: #000;
  border-bottom: 0.053333rem solid #f24548;
  padding: 0 0.32rem;
  height: 1.066667rem;
  line-height: 1.066667rem;
  font-weight: 500;
  display: inline-block;
}