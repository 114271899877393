.formGroup {
  padding: 0 3.5%;
  /* margin-top: 2px; */
}

.otherOption {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 1.333333rem;
  line-height: 1.333333rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.373333rem;
}

.loginBtn {
  border: none;
  width: 100%;
  height: 1.066667rem;
  line-height: 1.066667rem;
  font-size: 0.426667rem;
  font-weight: 400;
  font-family: 'Microsoft YaHei';
  text-align: center;
}