:global #root {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  background-size: 100% auto;
  overflow: scroll;
  font-family: 'PingFangSC-Regular';
  -webkit-overflow-scrolling: touch;
}

h1,
h2,
h3 {
  font-weight: normal;
}

input {
  border: none;
}

input:focus {
  outline: none;
}