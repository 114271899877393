.inputItem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  border-bottom: 0.026667rem solid #dedee4;
  height: 1.333333rem;
  line-height: 1.333333rem;
  font-size: 0.426667rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.inputItem label {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  height: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.inputItem .label {
  color: #333;
  margin-right: 0.266667rem;
}

.inputItem .label.labelSeparete {
  margin-right: 0;
  padding-right: 0.266667rem;
  border-right: 0.026667rem solid #dedee4;
}

.inputItem .itemContent {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.inputItem .itemContent input,
.inputItem .itemContent div {
  width: 100%;
  font-size: 0.426667rem;
  height: 1.066667rem;
  line-height: 1.066667rem;
  background: #fff;
  border-radius: 0.106667rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  position: relative;
  padding: 0 2%;
}

.inputItem input::-webkit-input-placeholder {
  color: #babac2;
}

.inputItem input:-moz-placeholder {
  color: #babac2;
}

.inputItem input::-moz-placeholder {
  color: #babac2;
}

.inputItem input:-ms-input-placeholder {
  color: #babac2;
}

.inputItem .captchaImg {
  width: 37%;
  height: 1.066667rem;
  margin-left: 0.133333rem;
}

.inputItem .captchaImg img {
  width: 100%;
  height: 100%;
  border-radius: 0.08rem;
}

.sendMsgBtn {
  height: 0.8rem;
  line-height: 0.8rem;
  font-size: 0.373333rem;
  color: #fff;
  border: none;
  font-family: 'Microsoft YaHei';
  display: inline-block;
  width: 37%;
  text-align: center;
  border-radius: 0.08rem;
  margin-left: 0.133333rem;
}

.sendMsg {
  background: #f24548;
}

.smsSending {
  background: #949890;
}

.errorWarn {
  color: #f54343;
  display: inline-block;
}