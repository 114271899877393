.moreaccount {
  line-height: 0.8rem;
  position: relative;
  height: 0.8rem;
  font-size: 0.533333rem;
  margin: 0.4rem 0;
  padding: 0 3.5%;
}

.moreaccount div {
  width: 100%;
  height: 0.373333rem;
  border-bottom: 0.026667rem solid #dfdfdf;
}

.moreaccount p {
  position: absolute;
  width: 2.666667rem;
  height: 0.8rem;
  line-height: 0.8rem;
  font-size: 0.32rem;
  background: #f8f8f8;
  top: 0;
  left: 50%;
  margin-left: -1.333333rem;
  text-align: center;
  color: #999;
  margin-bottom: 0.533333rem;
}

.otherlogin {
  overflow: hidden;
  text-align: center;
}

.otherlogin a {
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;
  border: 0.026667rem solid #dedee4;
  line-height: 1.2rem;
  color: #fff;
  text-align: center;
  margin: 0 0.666667rem;
  display: inline-block;
}

.otherlogin a img {
  height: auto;
  width: 0.586667rem;
  line-height: 0.666667rem;
  vertical-align: middle;
}

:global(.num3) {
  width: 33%;
  float: left;
  text-align: center;
}

:global(.num3) a {
  display: block;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  padding: 0.266667rem;
  float: none;
  height: auto;
  -webkit-tap-highlight-color: rgba(255, 0, 0, 0);
}

:global(.num3) a img {
  width: 100%;
  height: auto;
  margin: 0;
}

:global(.bg_weixin) {
  background-color: #86be29;
}

:global(.bg_blue) {
  background: #58cdee;
}

:global(.bg_red) {
  background: #cf3934;
}